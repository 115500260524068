import { WagmiConfig, createClient, configureChains, mainnet, goerli } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import Header from './components/Header.js'
import './App.css'
import ComingSoon from './components/ComingSoon';
import Home from './components/Home';
import Footer from './components/Footer';
 
const { provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [publicProvider()],
)
 
const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
})

const contractAddress = "0xbFe8F5a81C0F6BBBa021B11C26Fc71200e436088"
const isSiteLive = false

function App() {
  return (
  <div className="relative h-full min-h-screen">
    <div className="relative">
      <WagmiConfig client={client}>
          <Header isSiteLive={isSiteLive}/>
          {!isSiteLive && <ComingSoon/>}
          {isSiteLive && <Home isSiteLive={isSiteLive} contractAddress={contractAddress} /> }
      </WagmiConfig>
    </div>
    <Footer/>
  </div>
  );
}

export default App;
