import {useAccount,useConnect,useDisconnect,useEnsName} from "wagmi";
import { InjectedConnector } from 'wagmi/connectors/injected'
import truncateEthAddress from "truncate-eth-address";
import YoloLogoAnimated from '../images/yolo-animated-logo.gif'

export default function Header({isSiteLive}) {
  const { address, isConnected, isConnecting } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { connect } = useConnect({connector: new InjectedConnector(),
    onSettled(data, error) {
      console.log('Connected account: ' + data?.account)
  },})
  const { disconnect } = useDisconnect()
 
  return (
    <div className="w-screen drop-shadow-lg bg-green-grad">
      <div className="flex items-center justify-between text-white">
        <div className="col-start-2">
          <a href="/">
            <img className="w-60 md:w-80 mx-auto md:ml-4 my-2" src={YoloLogoAnimated} alt="Yolo Kidz" />
          </a>
        </div>
      {!isConnected && isSiteLive && (
        <div className="col-start-3">
          <button className="bg-orange-500 hover:bg-orange-400 mr-4 w-48 py-2 rounded-lg  md:mr-8 " onClick={connect}>
            {!isConnecting && <p className="text-xs sm:text-sm">connect</p>}
            {isConnecting && <p className="text-xs sm:text-sm animate-pulse">connecting</p>}
          </button>      
        </div>
        )}
        {isConnected && isSiteLive && (
          <div className="place-items-end"><button className="bg-orange-500  hover:bg-orange-400 mr-4 w-48 md:w-48 py-2 md:mr-8 rounded-lg" onClick={disconnect}>
            {ensName ? <p className="text-sm">{ensName}</p> : <p className="text-xs sm:text-sm">{truncateEthAddress(address)}</p>}
          </button></div>
        )}
      </div>
    </div>
  );
}
