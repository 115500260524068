import React from 'react'
import { MerkleTree } from 'merkletreejs'
import whitelist from '../abi/whitelist.json'
import claimlist from '../abi/free-claims.json'
import { utils } from 'ethers'
import { Buffer } from "buffer/";
window.Buffer = window.Buffer || Buffer;

const padBuffer = (addr) => {
  return Buffer.from(addr.substr(2).padStart(32*2, 0), 'hex')
}
const whitelistLeaves = whitelist.map(v => padBuffer(v))
const whitelistTree = new MerkleTree(whitelistLeaves, utils.keccak256, { sort: true })
// eslint-disable-next-line
const whitelistRoot = whitelistTree.getHexRoot()
//console.log("whitelist root: " + whitelistRoot)
export const getWhitelistProof = (address) => {
  const whitelistProof = whitelistTree.getHexProof(padBuffer(address))
  //console.log("whitelist proof: " + whitelistProof)
  return whitelistProof;
}

const claimlistLeaves = claimlist.map(v => padBuffer(v))
const claimlistTree = new MerkleTree(claimlistLeaves, utils.keccak256, { sort: true })
// eslint-disable-next-line
const claimlistRoot = claimlistTree.getHexRoot()
export const getClaimProof = (address) => {
  const claimlistProof = claimlistTree.getHexProof(padBuffer(address))
  //console.log("claim proof: " + claimlistProof)
  return claimlistProof;
}
//console.log("claimlist root: " + claimlistRoot)
//console.log("claimlist proof: " + claimlistProof)

export default function Merkle() {
  return (
    <div>MerkleTree</div>
  )
}