import { useState } from 'react'
import { ethers } from 'ethers'
import TimeConverter from './TimeConverter';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

function Admin({dataSaleConfig, hasDevMinted, yoloABI, contractAddress}) {
    const currentWhitelistStartDate = TimeConverter(dataSaleConfig?.whitelistSaleStartTime.toString())
    const currentPublicStartDate = TimeConverter(dataSaleConfig?.publicSaleStartTime.toString())
    const currentClaimStartDate = TimeConverter(dataSaleConfig?.claimStartTime.toString())

    const [claimStartTime, setClaimStartTime] = useState("")
    const [whitelistSaleStartTime, setWhitelistSaleStartTime] = useState("")
    const [publicSaleStartTime, setPublicSaleStartTime] = useState("")
    const [publicPrice, setPublicPrice] = useState("")
    const [whitelistPrice, setWhitelistPrice] = useState("")

    const startClaim = +new Date(claimStartTime);
    const startWhitelist = +new Date(whitelistSaleStartTime);
    const startPublic = +new Date(publicSaleStartTime);

    const [whitelistRoot, setWhitelistRoot] = useState("")
    const [claimRoot, setClaimRoot] = useState("")

    const { config: setupConfig } = usePrepareContractWrite({
        address: contractAddress,
        abi: yoloABI,
        functionName: 'setupSaleInfo',
        enabled: whitelistPrice && publicPrice && startClaim && startWhitelist && startPublic,
        args: [startClaim / 1000, startWhitelist / 1000, startPublic / 1000, (publicPrice * 1000000000000000000).toString(), (whitelistPrice * 1000000000000000000).toString()],
      })
    const { write: writeSetup } = useContractWrite(setupConfig)
    const waitForSetupTransaction = useWaitForTransaction({
        hash: writeSetup?.data?.hash,
    })

    const { config: devMintConfig } = usePrepareContractWrite({
        address: contractAddress,
        abi: yoloABI,
        functionName: 'devMint',
        enabled: !hasDevMinted,
      })
    const { write: writeDevMint } = useContractWrite(devMintConfig)
    const waitForTransaction = useWaitForTransaction({
        hash: writeDevMint?.data?.hash,
    })

    const { config: whitelistRootConfig } = usePrepareContractWrite({
        address: contractAddress,
        abi: yoloABI,
        functionName: 'setWhitelistRoot',
        enabled: whitelistRoot,
        args: [whitelistRoot],
      })
    const { write: writeWhitelistRoot } = useContractWrite(whitelistRootConfig)
    const waitForWhitelistRootTransaction = useWaitForTransaction({
        hash: writeWhitelistRoot?.data?.hash,
    })

    const { config: claimRootConfig } = usePrepareContractWrite({
        address: contractAddress,
        abi: yoloABI,
        functionName: 'setClaimRoot',
        enabled: claimRoot,
        args: [claimRoot],
      })
    const { write: writeClaimRoot } = useContractWrite(claimRootConfig)
    const waitForClaimRootTransaction = useWaitForTransaction({
        hash: writeClaimRoot?.data?.hash,
    })

  return (
    <>
    <div className="bg-slate-800 md:max-w-3xl rounded-2xl mx-auto mt-4">
        <div className="p-4 text-center">
            <p className="text-xl text-orange-500 text-center border-b-2 border-orange-500">Admin Panel</p>
            <p className="text-l text-white my-4">Current contract config</p>
            <p className="text-orange-500 text-xl">Claim</p>
            <p className="text-white text-sm">{currentClaimStartDate}</p>
            <p className="text-orange-500 text-xl">Whitelist</p>
            <p className="text-white text-sm">{currentWhitelistStartDate}</p>
            <p className="text-orange-500 text-xl">Public</p>
            <p className="text-white text-sm">{currentPublicStartDate}</p>
            <p className="text-orange-500 text-xl">Whitelist price</p>
            <p className="text-white text-sm">{ethers.utils.formatEther(dataSaleConfig?.whitelistPrice.toString())} ETH</p>
            <p className="text-orange-500 text-xl">Public price</p>
            <p className="text-white text-sm">{ethers.utils.formatEther(dataSaleConfig?.publicPrice.toString())} ETH</p>
            <button className="bg-orange-500 px-4 py-2 my-4 text-white" disabled={hasDevMinted} onClick={()=>writeDevMint?.()}>
                {hasDevMinted ? <p>Already Minted</p>:<p>Dev Mint</p>}
            </button>
        </div>
        <form className="flex flex-col p-4 bg-slate-700" onSubmit={(e) => { e.preventDefault(); }}>
            <label className="text-white"><p>Claim start time</p></label>
            <input className="my-2" type="datetime-local" required value={claimStartTime} onChange={(e) => setClaimStartTime(e.target.value)}/>
            <label className="text-white"><p>Whitelist start time</p></label>
            <input className="my-2" type="datetime-local" required value={whitelistSaleStartTime} onChange={(e) => setWhitelistSaleStartTime(e.target.value)}/>
            <label className="text-white"><p>Public start time</p></label>
            <input className="my-2" type="datetime-local" required value={publicSaleStartTime} onChange={(e) => setPublicSaleStartTime(e.target.value)}/>
            <label className="text-white"><p>Public price</p></label>
            <input className="my-2" type="text" required value={publicPrice} onChange={(e) => setPublicPrice(e.target.value)}/>
            <label className="text-white"><p>Whitelist price</p></label>
            <input className="my-2" type="text" required value={whitelistPrice} onChange={(e) => setWhitelistPrice(e.target.value)}/>
            <button className="bg-green-500 text-white px-4 py-2" onClick={()=>writeSetup?.()}>
                <p>Update config</p>
            </button>
        </form>
        <div>
            <form className="flex flex-col p-4" onSubmit={(e) => { e.preventDefault(); }}>
                <label className="text-white"><p>Set claim root</p></label>
                <input type="text" required value={claimRoot} onChange={(e) => setClaimRoot(e.target.value)}/>
                <button className="bg-green-500 text-white px-4 py-2" onClick={()=>writeClaimRoot?.()}>
                    <p>Update Root</p>
                </button>
            </form>
            <form className="flex flex-col  p-4" onSubmit={(e) => { e.preventDefault(); }}>
                <label className="text-white"><p>Set whitelist root</p></label>
                <input type="text" required value={whitelistRoot} onChange={(e) => setWhitelistRoot(e.target.value)}/>
                <button className="bg-green-500  text-white px-4 py-2" onClick={()=>writeWhitelistRoot?.()}>
                    <p>Update Root</p>
                </button>
            </form>
        </div>
    </div>   
    </>
  )
}

export default Admin