import React from 'react'
import Twitter from '../images/twitter-tp.png'
import Discord from '../images/discord-tp.png'
import Opensea from '../images/opensea-tp.png'
import Looks from '../images/looks-tp.png'

export default function Footer() {
  const navList = (
    <ul className="mt-2 p-2 justify-center flex gap-4">
      <li className="hover:scale-110">
        <a href="https://twitter.com/Yolo_Kidz" target="_blank" rel="noreferrer" className="flex items-center">
          <img className="w-10" src={Twitter} alt="twitter"/>
        </a>
      </li>
      <li className="hover:scale-110">
        <a href="https://discord.gg/Xjp8D2EYhF" target="_blank" rel="noreferrer" className="flex items-center">
        <img className="w-10" src={Discord} alt="discord"/>
        </a>
      </li>
      <li className="hover:scale-110">
        <a href="https://opensea.io/collection/yolo-kidz" target="_blank" rel="noreferrer" className="flex items-center">
        <img className="w-10" src={Opensea} alt="opensea"/>
        </a>
      </li>
      <li className="hover:scale-110">
        <a href="https://opensea.io/collection/yolo-kidz" target="_blank" rel="noreferrer" className="flex items-center">
        <img className="w-10" src={Looks} alt="looks"/>
        </a>
      </li>
    </ul>
  )
  return (
    <div className="bg-gray-800 w-full absolute bottom-0">
      <div>{navList}</div>
    </div>
  )
}
