import React from 'react'

export default function ComingSoon() {
  return (
    <>
        <div className="absolute inset-x-0 top-32"><h1 className="text-xl md:text-3xl text-green-300 animate-pulse  text-center">Coming soon</h1></div>
        <div className="absolute inset-x-0 bottom-20"><h1 className="text-xl m-4 md:text-3xl animate-pulse text-green-300 text-center">A project by BR33D</h1></div>
    </>
  )
}
